/* eslint-disable react/no-danger */
import React, { memo } from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import Button from 'react-bootstrap/Button';

import acl from 'tools/acl';
import seo from 'tools/seo';

import { getArtworkNews, markArtworkNewsAsRead } from 'actions/NewsActions';

import Loading from 'components/Loading';
import NewsCardList from 'components/NewsCardList';

import CommunSVGs from 'svgs/commun';

import ArtworkInfo from './ArtworkInfo';

type Props = {
  userProfile: Object,
  artwork: Object,
};

const ArtworkNews = ({ userProfile, artwork }: Props) => {
  const { t } = useTranslation('artwork');
  const isUserArtwork = acl.isAuthor(userProfile, artwork);

  const [page, setPage] = React.useState(1);

  const location = useLocation();
  const params = useParams();

  const news = useSelector(state => state.news.artworkNews);
  const isFetching = useSelector(state => _get(state.news, 'isFetching.getArtworkNews'));
  const newsTotal = useSelector(state => state.news.artworkNewsTotal);
  const newsLast = useSelector(state => state.news.artworkNewsLast);

  // actions
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getArtworkNews(params.artworkName, 1));
    if (acl.getToken()) {
      const timer = setTimeout(() => dispatch(markArtworkNewsAsRead(params.artworkName)), 2000);
      return () => {
        clearTimeout(timer);
      };
    }
    return null;
  }, [params.artworkName, dispatch]);

  const loadMore = async () => {
    const currentPage = page + 1;
    if (isFetching || currentPage > newsLast) {
      return;
    }
    setPage(currentPage);
    await dispatch(getArtworkNews(params.artworkName, currentPage));
  };

  return (
    <>
      <Helmet>
        {seo.title(
          `Read news about ${_get(artwork, 'name') || ''} created by ${
            _get(artwork, 'author.username') || ''
          } - Flowfo`,
        )}
      </Helmet>
      <ArtworkInfo artwork={artwork} className="d-block d-lg-none" />
      <div className="Artwork-separatorLine d-block d-lg-none" />
      {!isFetching && !_isEmpty(news) && (
        <>
          {isUserArtwork && (
            <div className="text-center mt-2 mb-3">
              <Link
                to={`/news/add?artwork=${artwork.id}&u=${location.pathname}${location.hash}`}
                className="Tab-button flowfont-bold"
              >
                {t('news-link-add')} <CommunSVGs.News width="24" height="24" className="ml-2" fill="var(--primary)" />
              </Link>
            </div>
          )}
          <NewsCardList list={news.filter(n => n.isPinned)} userProfile={userProfile} pinned />
          <InfiniteScroll
            pageStart={1}
            loadMore={loadMore}
            hasMore={news.length < newsTotal}
            loader={<Loading key={0} />}
            initialLoad={false}
            className="News-wrapper"
          >
            <NewsCardList list={news} userProfile={userProfile} noPinned />
          </InfiniteScroll>
        </>
      )}
      {!isFetching && _isEmpty(news) && (
        <div className="text-center">
          <div className="h6 flowfont-bold text-secondary fs-125">{t('news-empty')}</div>
          <div className="flowfont-thin fs-100">
            <p dangerouslySetInnerHTML={{ __html: t('news-empty-paragraph') }} />
            {isUserArtwork && (
              <Button variant="primary" className="Tab-button mt-2 mb-5 rounded-pill">
                <Link to="/news/add" className="white flowfont-medium fs-09 mr-1">
                  <CommunSVGs.News width="20px" height="20px" fill="#fff" className="mr-2" />
                  {t('news-link-add')}
                </Link>
              </Button>
            )}
          </div>
        </div>
      )}
      {isFetching && <Loading />}
    </>
  );
};

export default memo(ArtworkNews);
