import moment from 'moment';

import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import _get from 'lodash/get';

import Button from 'react-bootstrap/Button';

import acl from 'tools/acl';
import artworkUtils from 'tools/artwork';

import Avatar from 'components/Avatar';
import CommentForm from 'components/CommentForm';

import CommunSVGs from 'svgs/commun';

import './CommentCard.scss';

type Props = {
  comment: Object,
  parentId: Number,
  artworkAuthor: Object,
  userProfile: Object,
  level: Boolean,
  onDelete: Function,
  onReport: Function,
};

export default function CommentCard({
  comment,
  comment: { id, author, createdAt, content, reported, comments },
  parentId,
  artworkAuthor,
  userProfile,
  level,
  onDelete,
  onReport,
}: Props) {
  const { t, i18n } = useTranslation('commentcardlist');
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [hover, setHover] = useState(false);

  const handleShowReply = () => {
    setShowReplyForm(true);
  };

  const handleSubmit = () => {
    setShowReplyForm(false);
  };

  if (!id) {
    return null;
  }
  const isReportedByMe = reported && reported.includes(`${userProfile.id},`);
  const isArtworkAuthor = acl.isAuthor(artworkAuthor, comment);
  return (
    <div className="CommentCard">
      <Link to={`/profile/${author.username}`} className="CommentCard-author">
        <Avatar
          src={artworkUtils.getAvatarUrl(_get(author, 'avatarFile'), 42)}
          alt="User"
          className="CommentCard-author-avatar"
        />
      </Link>
      <div className="CommentCard-wrapper">
        <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
          <div className="CommentCard-header">
            <div className="CommentCard-author">
              <Link to={`/profile/${author.username}`}>
                <h5 className="CommentCard-username text-secondary">{author.username}</h5>
              </Link>
            </div>
            {isArtworkAuthor && (
              <span className="CommentCard-artworkauthor ml-1 pb-1 badge badge-pill badge-pinkRed flowfont-regular">
                {t('creator')}
              </span>
            )}
            <span className="CommentCard-date text-neutralSubtle">
              - {moment(createdAt).locale(i18n.language).fromNow()}
            </span>
            {hover && acl.isAuthor(userProfile, comment) && (
              <div className="CommentCard-actions">
                <div
                  className="CommentCard-icon"
                  onClick={() => onDelete(id)}
                  role="presentation"
                  title="Remove this comment"
                >
                  <CommunSVGs.Bin width="20" height="20" fill="#707070" />
                </div>
              </div>
            )}
            {hover && userProfile && userProfile.id && !acl.isAuthor(userProfile, comment) && (
              <div className="CommentCard-actions">
                <div
                  className={`CommentCard-icon${isReportedByMe ? 'no-action' : ''}`}
                  onClick={() => {
                    if (!isReportedByMe) onReport(id);
                  }}
                  role="presentation"
                  title="Report this comment"
                >
                  <CommunSVGs.Block width="18" height="18" fill={isReportedByMe ? `#CC0000` : `#707070`} />
                </div>
              </div>
            )}
          </div>

          <div className="CommentCard-content">
            <div className="CommentCard-text">{content}</div>
          </div>
          {!level && (
            <div className="CommentCard-footer">
              {!showReplyForm && acl.isConnected(userProfile) && (
                <Button variant="link" className="CommentCard-action" onClick={handleShowReply}>
                  {t('reply')}
                </Button>
              )}
              {showReplyForm && <CommentForm commentId={id} parentId={parentId} onSubmit={handleSubmit} />}
            </div>
          )}
        </div>
        {comments && comments.length > 0 && (
          <div className="CommentCard-replys">
            {comments.map(reply => (
              <CommentCard
                key={reply.id}
                comment={reply}
                artworkAuthor={artworkAuthor}
                userProfile={userProfile}
                onDelete={onDelete}
                onReport={onReport}
                level
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
