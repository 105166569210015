import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Button from 'react-bootstrap/Button';

import Loading from 'components/Loading';

import CommentCard from './CommentCard';
import './CommentCardList.scss';

type Props = {
  list: Object,
  artworkAuthor: Object,
  userProfile: Object,
  parentId: Number,
  onDelete: Function,
  onReport: Function,
};
export default function CommentCardList({ list, parentId, artworkAuthor, userProfile, onDelete, onReport }: Props) {
  const { t } = useTranslation('commentcardlist');

  const [currentPage, setCurrentPage] = useState(1);

  const handleShowMore = () => {
    setCurrentPage(currentPage + 1);
  };

  if (!list) {
    return <Loading />;
  }

  const pageSize = 5;
  const currentCommentsShow = pageSize * currentPage;
  const hasMore = list.length > currentCommentsShow;
  let comments = list;
  if (hasMore) {
    comments = list.slice(0, currentCommentsShow);
  }

  return (
    <div className="CommentCardList">
      {comments.map(comment => (
        <CommentCard
          key={comment.id}
          comment={comment}
          parentId={parentId}
          artworkAuthor={artworkAuthor}
          userProfile={userProfile}
          onDelete={onDelete}
          onReport={onReport}
        />
      ))}
      {list.length === 0 && <p className="text-neutral text-center mt-5 mb-5">{t('list-empty')}</p>}
      {hasMore && (
        <div className="text-center">
          <Button variant="link" className="CommentCardList-action" onClick={handleShowMore}>
            {t('button-showmore')}
          </Button>
        </div>
      )}
    </div>
  );
}
