/* eslint-disable jsx-a11y/label-has-for */

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';

import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

import { addComment } from 'actions/CommentActions';

import CommunSVGs from 'svgs/commun';

import './CommentForm.scss';

type Props = {
  commentId: Number,
  fileId: Number,
  newsId: Number,
  parentId: Number,
  onSubmit: Function,
  isFetching: Boolean,
  addCommentAction: Function,
};
export default function CommentForm({ commentId, fileId, newsId, parentId, onSubmit }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation('commentform');

  const isFetching = useSelector(state => state.comment?.isFetching?.addComment);

  const [message, setMessage] = useState('');
  const handleChange = e => {
    setMessage(e.target.value);
  };

  const handleSubmit = () => {
    if (!message) return;
    dispatch(addComment({ commentId, fileId, newsId, message, parentId }));
    setMessage('');
    if (onSubmit) onSubmit();
  };

  return (
    <div className="CommentForm input form-group">
      <label className="Input-label flowfont-medium text-uppercase" htmlFor="message">
        {t('comment-input-label')}
      </label>
      <InputGroup>
        <TextareaAutosize
          id="message"
          name="message"
          placeholder={t('comment-input-placeholder')}
          value={message}
          onChange={handleChange}
          className="CommentForm-field Textarea-field form-control"
          autoFocus
        />
        <InputGroup.Append>
          <Button
            variant="outline-neutralVerySubtle"
            className="CommentForm-button"
            onClick={handleSubmit}
            disabled={isFetching}
          >
            {isFetching ? '...' : <CommunSVGs.BubbleComment width="24" height="20" fill="var(--neutral)" />}
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </div>
  );
}
