import moment from 'moment';

import React from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import acl from 'tools/acl';
import artworkUtils from 'tools/artwork';

import { getNewsComments, removeComment, reportComment } from 'actions/CommentActions';

import CommentCardList from 'components/CommentCardList';
import CommentForm from 'components/CommentForm';
import Cover from 'components/Cover';
import PlayerCover from 'components/Cover/PlayerCover';

import CommunSVGs from 'svgs/commun';

import './NewsCard.scss';

type Props = {
  news: Object,
  userProfile: Object,
  pinned: Boolean,
  openComments: Boolean,
  location: Object,
  showCoverRightSide: Boolean,
  onDelete: Function,
  posts: Array,
  getNewsCommentsAction: Function,
  removeCommentAction: Function,
  reportCommentAction: Function,
  i18n: Object,
};

class NewsCard extends React.PureComponent<Props> {
  state = {
    showNews: false,
  };

  componentDidMount() {
    const { openComments } = this.props;
    if (openComments) this.handleShowNews();
  }

  handleShowNews = () => {
    const { news, getNewsCommentsAction } = this.props;
    const { showNews } = this.state;
    if (!showNews) {
      getNewsCommentsAction(news.id);
    }
    this.setState({ showNews: !showNews });
  };

  onDelete = id => {
    const { removeCommentAction, news } = this.props;
    removeCommentAction(id, news.id);
  };

  onReport = id => {
    const { userProfile, news, reportCommentAction } = this.props;
    reportCommentAction(id, news.id, userProfile.id);
  };

  render() {
    const { news, userProfile, pinned, location, posts, showCoverRightSide, onDelete, i18n } = this.props;
    const { showNews } = this.state;

    if (!news && !userProfile) {
      return null;
    }

    const username = news.artwork.author.username || userProfile.username;

    return (
      <div className="NewsCard">
        {showCoverRightSide && (
          <div className="NewsCard-coverRightSide d-none d-md-block">
            <Link to={`/artwork/${username}/${news.artwork.uniqueName}`}>
              <Cover src={artworkUtils.getCoverUrl(news.artwork, 63)} alt={news.artwork.name} />
            </Link>
          </div>
        )}

        <div className={`NewsCard-wrapper card ${showCoverRightSide ? 'two-columns' : ''}`}>
          <div className="NewsCard-header">
            <div className="NewsCard-artwork">
              <Link to={`/artwork/${username}/${news.artwork.uniqueName}`}>
                <img
                  src={artworkUtils.getCoverUrl(news.artwork, 18)}
                  alt={news.artwork.name}
                  className="NewsCard-cover"
                />
                <div className="NewsCard-artworkName h6 text-neutralSubtle fs-08">{news.artwork.name}</div>
              </Link>
            </div>
            <span className="NewsCard-date text-neutralSubtle fs-08">
              {moment(news.createdAt).locale(i18n.language).fromNow()}
            </span>
          </div>

          {news.artworkNewsFiles.length > 0 && (
            <div className={`NewsCard-images layout${news.artworkNewsFiles.length}`}>
              {news.artworkNewsFiles.map((file, key) => (
                <PlayerCover
                  key={file.file.id}
                  src={artworkUtils.getFileUrl(file.file, 498)}
                  alt={news.title}
                  playerParams={{ type: 'news', id: news.id, fileId: file.file.id }}
                  className={`NewsCard-imageWrapper NewsCard-imageWrapper-${key + 1}`}
                />
              ))}
            </div>
          )}

          <div className="NewsCard-content">
            <h3 className="NewsCard-title">{news.title}</h3>
            {/** eslint-disable-next-line react/no-danger */}
            <div className="NewsCard-content" dangerouslySetInnerHTML={{ __html: news.content }} />
          </div>
          <div className={`NewsCard-footer${showNews ? ' with-news' : ''}`}>
            <div className="NewsCard-actions">
              {acl.isAuthor(userProfile, news.artwork) && (
                <>
                  <div className="NewsCard-icon" onClick={() => onDelete(news)} role="presentation">
                    <CommunSVGs.Bin width="20px" height="20px" fill="var(--primary)" />
                  </div>
                  <div className="NewsCard-icon">
                    <Link to={`/news/edit/${news.id}?u=${location.pathname}${location.hash}`}>
                      <CommunSVGs.Edit width="20px" height="20px" fill="var(--primary)" />
                    </Link>
                  </div>
                </>
              )}
            </div>
            <div className="NewsCard-actions">
              {pinned && news.isPinned && (
                <div className="NewsCard-right-icon mr-2">
                  <CommunSVGs.Pin width="20" height="20" fill="var(--neutral)" />
                </div>
              )}
              <div className="NewsCard-right-icon cursor" onClick={this.handleShowNews} role="presentation">
                {news.totalComments > 0 && (
                  <span className="text-neutralSubtle flowfont-medium fs-113 mr-1">{news.totalComments}</span>
                )}
                <CommunSVGs.BubbleComment
                  className="NewsCard-commentIcon"
                  width="26"
                  height="26"
                  fill="var(--neutralSubtle)"
                />
              </div>
              {/* <CommunSVGs.Favorites width="20px" height="20px" fill="var(--secondary)" /> */}
            </div>
          </div>
          {showNews && (
            <div className="NewsCard-comments">
              {acl.isConnected(userProfile) && <CommentForm newsId={news.id} />}
              <div className="NewsCard-comments-posts">
                <CommentCardList
                  list={posts ? posts[news.id] : null}
                  parentId={news.id}
                  artworkAuthor={news.artwork.author}
                  userProfile={userProfile}
                  onDelete={this.onDelete}
                  onReport={this.onReport}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userProfile: state.user.privateProfile,
  posts: state.comment.newsComments,
});

const mapDispatchToProps = dispatch => ({
  getNewsCommentsAction: id => dispatch(getNewsComments(id)),
  removeCommentAction: (id, newsId) => dispatch(removeComment(id, newsId)),
  reportCommentAction: (id, newsId, userId) => dispatch(reportComment(id, newsId, userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('newscard')(withRouter(NewsCard)));
