import {
  REPORT_COMMENT,
  GET_FILE_COMMENTS,
  GET_NEWS_COMMENTS,
  ADD_COMMENT,
  REMOVE_COMMENT,
} from '../constants/ActionTypes';

export function getFileComments(id, page) {
  return {
    type: GET_FILE_COMMENTS,
    id,
    page,
  };
}

export function getNewsComments(id, page) {
  return {
    type: GET_NEWS_COMMENTS,
    id,
    page,
  };
}

export function addComment(params) {
  return {
    type: ADD_COMMENT,
    params,
  };
}

export function removeComment(id, parentId) {
  return {
    type: REMOVE_COMMENT,
    id,
    parentId,
  };
}

export function reportComment(id, parentId, userId) {
  return {
    type: REPORT_COMMENT,
    id,
    parentId,
    userId,
  };
}
