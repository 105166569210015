import React from 'react';

import Loading from 'components/Loading';

import NewsCard from './NewsCard';
import './NewsCardList.scss';

type Props = {
  list: Object,
  userProfile: Object,
  pinned: Boolean,
  noPinned: Boolean,
  showCoverRightSide: Function,
  onDelete: Function,
};

const NewsCardList = ({ list, userProfile, pinned, noPinned, showCoverRightSide, onDelete }: Props) => {
  if (!list) {
    return <Loading />;
  }

  return (
    <div className="NewsCardList">
      {list.map(item => {
        if (noPinned && item.isPinned) return null;
        return (
          <NewsCard
            news={item}
            key={item.id}
            userProfile={userProfile}
            pinned={pinned}
            showCoverRightSide={showCoverRightSide}
            onDelete={onDelete}
          />
        );
      })}
    </div>
  );
};

export default NewsCardList;
