import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import acl from 'tools/acl';

import { getUserNews, deleteNews } from 'actions/NewsActions';
import { getPrivateProfile, markNewsAsRead } from 'actions/UserActions';

import ErrorPage from 'components/ErrorPage';
import Loading from 'components/Loading';
import NewsCardList from 'components/NewsCardList';

import CommunSVGs from 'svgs/commun';

export default function Home() {
  const dispatch = useDispatch();
  const { t } = useTranslation('home');

  const news = useSelector(state => state.news);
  const userProfile = useSelector(state => state.user.privateProfile);

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (acl.getToken()) {
      dispatch(getPrivateProfile());
      dispatch(getUserNews(1));
    }
    if (acl.getToken()) {
      const timer = setTimeout(() => dispatch(markNewsAsRead()), 2000);
      return () => {
        clearTimeout(timer);
      };
    }
    return null;
  }, [dispatch]);

  const loadMore = async () => {
    const currentPage = page + 1;
    if (news.userNewsIsFetching || (news.userNewsLast && currentPage > news.userNewsLast)) {
      return;
    }
    setPage(currentPage);
    await dispatch(getUserNews(currentPage));
  };

  const handleDelete = data => {
    dispatch(deleteNews(data.id));
  };

  const renderNoNews = () => {
    return (
      <div className="text-center mt-5">
        <div className="h6 flowfont-title text-secondary fs-125">{t('global:no-content')}</div>
        <div className="flowfont-regular">
          <p className="text-neutral mb-0">
            {t('empty-paragraph1')} <CommunSVGs.Favorites height="16" fill="#707070" />
          </p>
          <p className="text-neutral">{t('empty-paragraph2')}</p>
          <Link to="/discover" className="Tab-link text-primary flowfont-medium fs-113">
            <CommunSVGs.Discover width="20px" height="20px" fill="var(--primary)" className="mr-2" />
            {t('discover-link')}
          </Link>
        </div>
      </div>
    );
  };

  if (news?.error?.getUserNews) {
    return <ErrorPage />;
  }
  if (!userProfile || !news?.userNews) {
    return <Loading fullPage />;
  }

  return (
    <div className="Home">
      {userProfile.artworksCount > 0 && (
        <div className="text-center pt-3 pb-3 d-md-none">
          <Link to="/news/add" className="Tab-button flowfont-bold">
            {t('write-news-link')} <CommunSVGs.News width="24" height="24" className="ml-2" fill="var(--primary)" />
          </Link>
        </div>
      )}
      {news.userNews.length === 0 && renderNoNews()}
      {news.userNews.length > 0 && (
        <InfiniteScroll
          pageStart={1}
          loadMore={loadMore}
          hasMore={news.userNews.length < news.userNewsTotal}
          loader={<Loading key={0} />}
          initialLoad={false}
          className="Home-wrapper"
        >
          <NewsCardList list={news.userNews} userProfile={userProfile} showCoverRightSide onDelete={handleDelete} />
        </InfiniteScroll>
      )}
    </div>
  );
}
