import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getArtworkNewsById } from 'actions/NewsActions';

import NewsCard from 'components/NewsCardList/NewsCard';

import './NewsPage.scss';

type Props = {
  news: Object,
  match: Object,
  getArtworkNewsByIdAction: Function,
};

class NewsForm extends Component<Props> {
  componentDidMount() {
    const {
      match: {
        params: { id: newsId },
      },
      getArtworkNewsByIdAction,
    } = this.props;
    if (newsId) {
      getArtworkNewsByIdAction(newsId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { id: newsId },
      },
      getArtworkNewsByIdAction,
    } = this.props;

    if (prevProps.news && `${prevProps.news.id}` !== newsId) {
      getArtworkNewsByIdAction(newsId);
    }
    if (prevProps.news && !newsId) {
      window.location.reload();
    }
  }

  render() {
    const { news } = this.props;
    if (!news) return null;
    return <NewsCard news={news} openComments />;
  }
}

const mapStateToProps = state => ({
  news: state.news.current,
});

const mapDispatchToProps = dispatch => ({
  getArtworkNewsByIdAction: id => dispatch(getArtworkNewsById(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation('newspage')(NewsForm)));
